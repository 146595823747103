<template>
  <div class="page-wrapper-full">
    <!-- start of Export modal -->
    <b-modal
      size="sm"
      id="export-edit-ai-suggestion"
      centered
      hide-footer
      hide-header
    >
      <h5 class="text-blue"><b>{{ "Export To" }}</b></h5>

      <div
        class="d-flex align-items-center my-1"
        v-for="(export_item, export_item_normal) in exports_items"
        :key="'export_pdf' + export_item_normal"
      >
        <input
          :name="'pdf' + export_item_normal"
          :id="'pdf' + export_item_normal"
          :value="export_item.name"
          v-model="pdf_status"
          type="checkbox"
          class="form-check-input mx-2"
        />
        <span
          class="material-icons-outlined icon-export-blue mx-2"
        >
          {{ export_item.icon }}
        </span>

        <b class="text-blue">{{ export_item.name }}</b>
      </div>

      <button @click="exportContent" class="btn btn-primary float-right mt-2">
        {{ "Export" }}
      </button>
    </b-modal>
    <!-- end of Export modal -->

    <PageLoader
      v-if="pageLoading || duration <= 100"
      :loading="pageLoading"
      :duration="duration"
      message="generatingLongerContent"
    ></PageLoader>

    <div v-if="all_discussion != 0" class="row">
      <div class="col-12 col-md-6 ps-4 pt-2">
        <div style="height: 2rem;">
          <button
            v-b-modal.export-edit-ai-suggestion
            class="btn-primary btn px-2 float-right mx-2"
          >
            {{ "Export" }}
          </button>
          <span
            v-b-tooltip.hover.top="{ variant: 'secondary' }"
            title="Copy"
            @click="copyContent"
            class="float-right cursor-pointer material-icons-outlined icon-blue"
          >
            content_copy
          </span>
        </div>

        <div
          class="w-100 pe-2"
          style="max-height: 800px; overflow-y: scroll;"
        >
          <template v-if="this.$route.query.category == 'Legal Argument'">
            <b>{{ "Statement" }}:</b>
            <button
              id="saveChangesButton"
              style="margin-top: 35px;"
              class="btn btn-primary btn-sm float-right me-1"
              @click="
                saveChange(content.id, content.type, quillStatement)
              "
            >
              {{ "Save Changes" }}
            </button>
            <quill-editor
              class="mt-2 mb-3"
              v-model="quillStatement"
              :options="optionsContent"
            ></quill-editor>
          </template>

          <template v-if="introduction.length != 0">
            <b>{{ "Introduction" }}:</b>
            <button
              id="saveChangesButton"
              style="margin-top: 35px;"
              class="btn btn-primary btn-sm float-right me-1"
              @click="
                saveChange(
                  introduction.id,
                  introduction.key,
                  quillIntroduction
                )
              "
            >
              {{ "Save Changes" }}
            </button>
            <quill-editor
              class="mt-2 mb-3"
              v-model="quillIntroduction"
              :options="optionsContent"
            ></quill-editor>
          </template>

          <template v-if="subject.length != 0">
            <b>{{ "Subject" }}:</b>
            <button
              id="saveChangesButton"
              style="margin-top: 35px;"
              class="btn btn-primary btn-sm float-right me-1"
              @click="
                saveChange(subject.id, subject.key, quillSubject)
              "
            >
              {{ "Save Changes" }}
            </button>
            <quill-editor
              class="mt-2 mb-3"
              v-model="quillSubject"
              :options="optionsContent"
            ></quill-editor>
          </template>

          <template v-if="question_presented.length != 0">
            <b>{{ "Questions Presented" }}:</b>
            <button
              id="saveChangesButton"
              style="margin-top: 35px;"
              class="btn btn-primary btn-sm float-right me-1"
              @click="
                saveChange(
                  question_presented.id,
                  question_presented.key,
                  quillQuestionsPresented
                )
              "
            >
              {{ "Save Changes" }}
            </button>
            <quill-editor
              class="mt-2 mb-3"
              v-model="quillQuestionsPresented"
              :options="optionsContent"
            ></quill-editor>
          </template>

          <template v-if="brief_answer.length != 0">
            <b>{{ "Brief Answer" }}:</b>
            <button
              id="saveChangesButton"
              style="margin-top: 35px;"
              class="btn btn-primary btn-sm float-right me-1"
              @click="
                saveChange(
                  brief_answer.id,
                  brief_answer.key,
                  quillBriefAnswer
                )
              "
            >
              {{ "Save Changes" }}
            </button>
            <quill-editor
              class="mt-2 mb-3"
              v-model="quillBriefAnswer"
              :options="optionsContent"
            ></quill-editor>
          </template>
          
          <b>{{ "Discussion" }}:</b>
          <div
            v-for="(discussions, index_tab) in all_discussion"
            :key="'discussions' + index_tab"
          >
            <button
              id="saveChangesButton"
              style="margin-top: 3px;"
              class="btn btn-primary btn-sm float-right me-1"
              @click="
                saveDiscussionChanges(
                  discussions.id,
                  quillDiscussionContent[index_tab]
                )
              "
            >
              {{ `Save Changes` }}
            </button>
            <quill-editor
              class="mt-2 mb-3 LegalAISug-discussion-editor"
              v-model="quillDiscussionContent[index_tab]"
              :ref="`quill-editor-${discussions.id}`"
              :options="optionsContent"
            ></quill-editor>
          </div>

          <template v-if="conclusion.length != 0">
            <b>
              {{ `Conclusion${
                $route.query.category != "Legal Argument"
                  ? " & Recommendations" : ""
              }` }}:
            </b>
            <button
              id="saveChangesButton"
              style="margin-top: 35px;"
              class="btn btn-primary btn-sm float-right me-1"
              @click="
                saveChange(
                  conclusion.id,
                  conclusion.key,
                  quillConclusion
                )
              "
            >
              {{ "Save Changes" }}
            </button>
            <quill-editor
              class="mt-2 mb-3"
              v-model="quillConclusion"
              :options="optionsContent"
            ></quill-editor>
          </template>

          <!-- recommendations is currently disabled -->
          <template v-if="recommendations.length != 0 && false">
            <b>{{ "Recommendations" }}:</b>
            <button
              id="saveChangesButton"
              style="margin-top: 35px;"
              class="btn btn-primary btn-sm float-right me-1"
              @click="
                saveChange(
                  recommendations.id,
                  recommendations.key,
                  quillRecommendations
                )
              "
            >
              {{ `Save Changes` }}
            </button>
            <quill-editor
              class="mt-2 mb-3"
              v-model="quillRecommendations"
              :options="optionsContent"
            ></quill-editor>
          </template>

          <template v-if="overturn.length != 0">
            <b class="bold">{{ "Legal Doctrines' Status" }}:</b>
            <div class="card mt-2 p-3">
              <div
                class="text-start"
                v-for="(overturn, overturn_index) in JSON.parse(
                  overturn.value
                )"
                :key="'overturn' + overturn_index"
              >
                <p
                  v-if="typeof overturn === 'string'"
                  v-b-tooltip.hover.top="{ variant: 'secondary' }"
                  title="No Suggestions"
                  class="align-self-center text-start"
                >
                  {{ overturn_index + 1 }})
                  {{ cleanText(overturn) }}
                </p>

                <p
                  v-else
                  class="align-self-start text-start"
                  v-for="(val, val_index) in overturn"
                  :key="'overturn_val' + val_index"
                >
                  {{ overturn_index + 1 }})
                  <a :href="val[0]" target="_blank">
                    {{ cleanText(val_index) }}
                  </a>
                </p>
              </div>
            </div>
          </template>

          <template v-if="citation.length != 0">
            <b class="bold">{{ "Citation" }}:</b>
            <div class="card mt-2 p-3">
              <div
                class="text-start"
                v-for="(citation, citation_index) in JSON.parse(
                  citation.value
                )"
                :key="'citation' + citation_index"
              >
                <p
                  v-if="typeof citation === 'string'"
                  v-b-tooltip.hover.top="{ variant: 'secondary' }"
                  title="No Suggestions"
                  class="align-self-center text-start"
                >
                  {{ citation_index + 1 }})
                  {{ cleanText(citation) }}
                </p>

                <p
                  v-else
                  class="align-self-start text-start"
                  v-for="(val, val_index) in citation"
                  :key="'citation_val' + val_index"
                >
                  {{ citation_index + 1 }})
                  <a :href="val[0]" target="_blank">
                    {{ cleanText(val_index) }}
                  </a>
                </p>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="col-12 col-md-6 pe-4 pt-2">
        <h4 class="text-blue text-center">
          <b>{{ `AI Suggestions for Discussion${
            chosen_discussion.theme === 'Discussion'
              ? "s" : ""
          }` }}</b>
        </h4>
        <p class="text-center text-muted font-14 mx-2 my-0">
          {{
            chosen_discussion.theme !== 'Discussion'
              ? "Choose a suggestion to add to its respective Discussion Theme editor"
              : "Upload a case law file to generate discussion"
          }}
        </p>

        <div class="card shadow rounded p-4 mt-3">
          <div class="row">
            <div class="col-12 col-lg-6">
              <h6 v-if="chosen_discussion.theme !== 'Discussion'">
                <b>Theme: {{ chosen_discussion.theme | capitalize }}</b>
              </h6>
              <h6 v-else-if="chosenCategory.type === 'case'">
                <b>Case Discussion:</b>
              </h6>

              <h6 v-else-if="chosenCategory.type === 'document'">
                <b>Document Discussion:</b>
              </h6>
            </div>

            <div
              v-if="
                permissions.includes(
                  'nexlaw.legal-argument-summarize-case'
                ) &&
                permissions.includes(
                  'nexlaw.legal-argument-summarize-document'
                ) &&
                chosenCategory.name != 'Upload Document'
              "
              class="col"
            >
              <p
                :class="['float-right',
                  `text-${file_in_summarize ||
                  chosenCategory.completedPerCentage < 100
                    ? 'muted' : 'blue-underline'}`
                ]"
                @click="
                  chosenCategory.completedPerCentage == 'Not enough data'
                    ? GenerateSummary(chosenCategory)
                  : chosenCategory.completedPerCentage >= 100
                    ? viewSummary() : ()=>{}
                "
              >
                {{
                  file_in_summarize
                    ? "Kindly wait"
                  : chosenCategory.completedPerCentage == 'Not enough data'
                    ? "Generate Summary"
                  : 100 > chosenCategory.completedPerCentage
                    ? `Generating Summary: ${Math.round(chosenCategory.completedPerCentage)}%`
                    : "View Summary"
                }}
              </p>
            </div>
          </div>

          <div class="d-flex mb-3">
            <div class="tabs__folderTabs mt-auto">
              <button
                :class="category.class"
                v-for="(
                  category, index_tab
                ) in chosen_discussion.alternatives"
                :key="'tabs_' + index_tab"
                @click="changeTab(index_tab)"
                v-show="category.type != 'upload'"
              >
                <span
                  class="folderTab__title"
                  v-if="category.type == 'case'"
                >
                  {{ `Case ${category.num}` }}
                </span>
                <span
                  class="folderTab__title"
                  v-if="category.type == 'document'"
                >
                  {{ "Doc " }}
                </span>
              </button>

              <button
                :class="category.class"
                v-for="(
                  category, index_tab
                ) in chosen_discussion.alternatives"
                :key="'tab' + index_tab"
                @click="changeTab(index_tab)"
                v-show="
                  permissions.includes(
                    'nexlaw.legal-argument-analyze-document'
                  ) &&
                  category.type == 'upload' &&
                  chosen_discussion.documents.length < 2
                "
              >
                <span class="folderTab__title">{{ "Upload" }}</span>
              </button>
            </div>
          </div>

          <!-- Upload Doc -->
          <template v-if="chosenCategory.name == 'Upload Document'">
            <input
              class="form-control mt-3 mb-1"
              type="file"
              id="formFile"
              ref="file"
              accept=".pdf"
              @change="handleFileChange"
            />
            <span class="font-12">
              <b-icon icon="info-circle" />
              Please upload PDF file only
            </span>

            <input
              class="form-control mt-3 mb-1"
              type="text"
              id="fileName"
              placeholder="File Name"
              autocomplete="off"
              v-model="selectedFileName"
            />
            <span class="font-12">
              <b-icon icon="info-circle" />
              Please rename your file
            </span>

            <div>
              <button
                @click="sendFile"
                id="buttonFile"
                class="btn btn-primary float-right mt-4"
              >
                <div class="row">
                  <div class="col-1">
                    <span class="material-icons-outlined">
                      file_upload
                    </span>
                  </div>
                  <div class="col">
                    {{ "Upload File" }}
                  </div>
                </div>
              </button>
            </div>
          </template>

          <!-- Completed Case/Doc -->
          <template v-else-if="
            chosenCategory.type != 'Upload Document' &&
            chosenCategory.elaborate_content.length != 0
          ">
            <a
              v-if="chosenCategory.type === 'document'"
              class="text-blue-underline mb-3"
              target="_blank"
              :href="chosenCategory.link"
            >
              <b>{{ cleanText(chosenCategory.name) }}</b>
            </a>
            <p v-else class="text-blue font-weight-bold">
              {{ cleanText(chosenCategory.name) }}
            </p>

            <template
              v-if="
                chosenCategory.elaborate_content[0].Discussion ===
                'could not find any result'
              "
            >
              <div class="my-3 m-auto" style="width: 200px">
                <img
                  src="@/assets/noData.png"
                  alt="No Data Image"
                />
              </div>
              <p class="text-center text-blue">
                <b>{{ "No Result, Kindly upload a relevant document" }}</b>
              </p>
            </template>

            <div
              v-else
              class="form-check suggestion"
              v-for="(
                one_case, case_index
              ) in chosenCategory.elaborate_content"
              :key="`cases${case_index}`"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :name="'cases' + case_index"
                :id="one_case"
                :value="case_index + ' ' + one_case.Discussion"
                v-model="replace_case"
                @change="replaceCase(one_case, chosenCategory.id)"
              />
              <label class="form-check-label">
                {{ one_case.Discussion }}
              </label>
            </div>
          </template>

          <!-- Uploading Doc -->
          <template
            v-else-if="
              chosenCategory.type != 'Upload Document' &&
              chosenCategory.elaborate_content.length == 0
            "
          >
            <a
              v-if="chosenCategory.type === 'document'"
              class="text-blue-underline mb-3"
              target="_blank"
              :href="chosenCategory.link"
            >
              <b>{{ cleanText(chosenCategory.name) }}</b>
            </a>
            <p v-else class="text-blue">
              <b>{{ cleanText(chosenCategory.name) }}</b>
            </p>

            <PageLoader :loading="true"></PageLoader>
          </template>
        </div>
      </div>
    </div>
    <template v-else-if="!hasData">
      <div class="mt-5 mb-2 m-auto" style="width: 200px">
        <img src="@/assets/noData.png" alt="No Data Image" />
      </div>
      <p class="text-center text-blue">
        <b>{{ "Could not find any Jurisdiction" }}</b>
      </p>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import Auth from "@/store/Auth.js";
import { ExportData } from "../../store/utils";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import LegalWriter from "@/store/LegalWriter.js";
export default {
  components: {
    PageLoader,
  },

  data() {
    return {
      permissions: localStorage.permissions,
      optionsContent: {
        modules: {
          toolbar: [
            // [{ 'size': [] }],
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline"],
            [{ color: [] }, { background: [] }],
            [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
            // [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            // [{ 'script': 'super' }, { 'script': 'sub' }],
            // [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
            // [{ 'direction': 'rtl' }, { 'align': [] }],
            // ["link"],
            // ['clean'],
          ],
        },
      },

      quillStatement: "",
      quillConclusion: "",
      quillIntroduction: "",
      quillSubject: "",
      quillQuestionsPresented: "",
      quillBriefAnswer: "",
      quillRecommendations: "",
      quillDiscussionContent: [],

      case_name: "",
      file_in_upload: false,
      pageLoading: true,
      selectedFile: null,
      selectedFileName: "",
      content: [],
      suggestion: "",
      status: false,
      changes: "SPECIFY THE LAW",
      categories: [
        { name: "Case 1", class: "folderTab active", description: "" },
        { name: "Case 2", class: "folderTab", description: "" },
        {
          name: "Upload Your Document",
          class: "folderTab",
          description:
            "NexLaw can analyze the discussion which relates to your statement from your document",
        },
      ],
      chosenCategory: "Case 1",

      // test cases:
      /*Based onthe given case of Subashini AIP Raasingam v Saravanan AIL Thangathoray, the issue of th vaidity of marriage.
        arses. In this case, the wife claims tha the husband had kept staying away from the marital home for long periods and had
        accused her of infidelity. She also mentions that he tiveatened to il her If she id not eave the marital home. Furthermore,
        she states tha he informed her hat ha hac converted to stam. These fact aise questions about the validly of heir
        marriage. If it can be proven tht the husband indeed converted to Islam and intended to practice his new religion, it could
        potential invalidate their previous mariage under certain egal systems. Therefore, itis crucial to thoroughiy examine the.

        In the case of Subashini AJP Rajasingam v Saravanan AIL Thangathoray, he issue of the validity of marriage was discussed.
        “The court recognized that the parties vere married under the Law Reform (Mariage and Divorce) Act, which governs,
        marriages in Malaysia. In this case, it is important o determine whether Soper's mariage fo his second wife Gertrude was
        valid If theif marriage was valid and recognized under the la, then Gertrude would be entied to calect the insurance
        money as his legal spouse. However, f Soper’ original wie can prove that ther marrage was stil vlc at the time of his
        death, she may have a im tothe insurance money as wel Therefore, it i crucial to examine the legal requirements fo a
        Vall mariage under Malaysian law and determine which party satsfes those requirements i order o determin who should
       */
      cases: ["", ""],
      all_discussion: [],
      chosen_discussion: [],
      duration: -1,
      hasData: false,
      pdf_status: [],
      replace_case: JSON.parse(localStorage.getItem("replace_case")) || [],
      content_to_export: "",
      content_to_copy: "",
      exports_items: [
        { name: "PDF", icon: "picture_as_pdf" },
        { name: "DOCUMENT", icon: "description" },
      ],
      file_in_summarize: false,
      timeout_timer: null,
      interval: null,
      introduction: "",
      introduction: "",
      citation: "",
      overturn:"",
      conclusion: "",
      subject: "",
      question_presented: "",
      brief_answer: "",
      recommendations: "",
      // entered: false,
      expanded: false,
      info: JSON.parse(localStorage.userInfo),
      document_credit_full: JSON.parse(localStorage.userInfo).nexlaw_credits
        .full.legal_argument_documents,
      document_credit_remain:
        JSON.parse(localStorage.userInfo).nexlaw_credits.full
          .legal_argument_documents -
        JSON.parse(localStorage.userInfo).nexlaw_credits.used
          .legal_argument_documents,
      case_credit_full: JSON.parse(localStorage.userInfo).nexlaw_credits.full
        .legal_argument_summarize_discussion_case,
      case_credit_remain:
        JSON.parse(localStorage.userInfo).nexlaw_credits.full
          .legal_argument_summarize_discussion_case -
        JSON.parse(localStorage.userInfo).nexlaw_credits.used
          .legal_argument_summarize_discussion_case,
      document_sum_credit_full: JSON.parse(localStorage.userInfo).nexlaw_credits
        .full.legal_argument_summarize_document,
      document_sum_credit_remain:
        JSON.parse(localStorage.userInfo).nexlaw_credits.full
          .legal_argument_summarize_document -
        JSON.parse(localStorage.userInfo).nexlaw_credits.used
          .legal_argument_summarize_document,
      // citationString: "",
    };
  },

  methods: {
    cleanText(text) {
      return text.replace(/<b>|<\/b>/g, "");
    },

    saveDiscussionChanges(id, content) {
      LegalWriter.SaveChanges(
        this.$route.params.id,
        {
          entity_id: id,
          entity_type: "discussion",
          content: content,
        },
        "LArg"
      )
        .then((response) => {
          this.$toast.success("Saved");
          // console.log("In save", this.replace_case);
          localStorage.setItem(
            "replace_case",
            JSON.stringify(this.replace_case)
          );
          // console.log("Obj", obj);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    saveChange(id, key, content) {
      var obj = {
        entity_id: id,
        entity_type: key == "argument" ? "statement" : "value",
        content: content,
      };

      if (key !== "argument") {
        obj.key = key;
      }

      LegalWriter.SaveChanges(this.$route.params.id, obj, "LArg")
        .then((res) => {
          this.$toast.success("Saved");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    copyContent() {
      this.saveContent();
      navigator.clipboard.writeText(
        this.content_to_copy.replace(/<[^>]*>/g, "")
      );
      this.$toast.success("Copied");
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
        this.selectedFileName = file.name;
        // console.log("File:", this.selectedFile);
        // console.log("Name:", this.selectedFileName);
      } else {
        this.selectedFile = null;
        this.selectedFileName = "";
      }
    },

    sendFile() {
      if (!this.selectedFile || this.selectedFile?.type !== "application/pdf") {
        this.$toast.error("Please Upload a PDF file");
      } else {
        this.file_in_upload = true;
        // console.log("Name:", this.selectedFileName);

        var bodyFormData = new FormData();
        bodyFormData.append("case_file", this.selectedFile);
        bodyFormData.append(
          "case_name",
          this.selectedFileName.replace(".pdf", "")
        );
        // console.log("upload", bodyFormData);

        LegalWriter.UploadDocument(this.chosen_discussion.id, bodyFormData)
          .then((response_main) => {
            this.selectedFile = "";
            this.case_name = "";
            this.$refs.file = "";

            const unique = response_main.data.data.discussions
              .find((x) => x.id === this.chosen_discussion.id)
              .documents.filter(
                ({ id: id1 }) =>
                  !this.chosen_discussion.alternatives.some(
                    ({ id: id2 }) => id2 === id1
                  )
              );
            // console.log(unique[0]);

            const obj = {
              id: unique[0].id,
              name: unique[0].case_name,
              link: unique[0].case_file,
              elaborate_content: [],
              class: "folderTab",
              type: "document",
              num: this.chosen_discussion.alternatives.length,
              completedPerCentage: unique[0].completedPerCentage,
            };
            this.chosen_discussion.alternatives.push(obj);
            this.chosen_discussion.documents.push(obj);

            this.changeTab(this.chosen_discussion.alternatives.length - 1)

            // this.citation.value = JSON.parse(this.citation.value) // [!! changes made to citation] - 9/5
            //   .push({
            //     [unique[0].case_name]: unique[0].case_file,
            //   });
            // this.saveChange(this.citation.id, "citations", this.citation);

            Auth.getMe().then((res) => {
              localStorage.setItem("userInfo", JSON.stringify(res.data.data));

              const userInfo = JSON.parse(localStorage.getItem("userInfo"));
              switch (userInfo?.nexlaw_credits) {
                case undefined:
                case "undefined":
                case null:
                case "null":
                case undefined:
                  localStorage.removeItem("accessToken");
                  localStorage.removeItem("userInfo");
                  this.$router.push("/login");
                  break;
              }

              this.document_credit_full =
                res.data.data.nexlaw_credits.full.legal_argument_documents;
              this.document_credit_remain =
                res.data.data.nexlaw_credits.full.legal_argument_documents -
                res.data.data.nexlaw_credits.used.legal_argument_documents;
            });

            this.file_in_upload = false;
            this.$toast.success(response_main.data.message);
          })
          .catch((error) => {
            console.log(error);
            this.file_in_upload = false;
          });
      }
      // Clear the file input
      this.$refs.file.value = null;
      this.selectedFileName = "";
    },

    // openEditor() {
    //   this.saveContent();

    //   localStorage.setItem("nexlaw_legal_content", this.content_to_copy);
    //   this.$router.push({
    //     name: "LegalWriterEditor",
    //     params: {
    //       id: this.$route.params.id,
    //     },
    //     query: {
    //       category: this.$route.query.category,
    //     },
    //   });
    // },

    loadResult(data) {
      data.discussions.forEach((element) => {
        element.original_content = element.content;
        element.alternatives.forEach((alternative, index) => {
          alternative.elaborate_content = JSON.parse(alternative.content);
          alternative.class = `folderTab${ index == 0 ? " active" : "" }`;
          alternative.type = "case";
          alternative.num = index + 1;
        });

        element.class = "";

        if (element.documents.length != 0) {
          element.documents.forEach((doc, index) => {
            // console.log(element.id, doc.id);

            if (!element.id) return;

            LegalWriter.openDocument(element.id, doc.id)
              .then((response) => {
                Auth.getMe().then((res) => {
                  localStorage.setItem(
                    "userInfo",
                    JSON.stringify(res.data.data)
                  );

                  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
                  switch (userInfo.nexlaw_credits) {
                    case undefined:
                    case "undefined":
                    case null:
                    case "null":
                      localStorage.removeItem("accessToken");
                      localStorage.removeItem("userInfo");
                      this.$router.push("/login");
                      break;
                  }

                  this.document_credit_full =
                    res.data.data.nexlaw_credits.full.legal_argument_documents;
                  this.document_credit_remain =
                    res.data.data.nexlaw_credits.full.legal_argument_documents -
                    res.data.data.nexlaw_credits.used.legal_argument_documents;
                });

                // console.log(response.data.data);

                var elaborate_content = [];
                if (
                  response.data.data.values.find(
                    (element) => element.key === "argument"
                  )
                ) {
                  elaborate_content = [
                    {
                      Discussion: response.data.data.values.find(
                        (element) => element.key === "argument"
                      ).value,
                    },
                  ];
                }

                element.alternatives.push({
                  id: doc.id,
                  name: response.data.data.case_name,
                  link: response.data.data.case_file,

                  elaborate_content: elaborate_content,
                  class: "folderTab",
                  type: "document",
                  num: index + 1,
                  completedPerCentage: response.data.data.completedPerCentage,
                });
              })
              .catch((error) => {
                console.log(error);
              });
          });
        } else {
          element.documents = [];
        }
        element.alternatives.push({
          name: "Upload Document",
          link: "",
          content: "",
          type: "upload",

          elaborate_content: [],
          class: "folderTab",
        });
        this.all_discussion.push(element);
        // console.log("All DIs", this.all_discussion);
      });

      this.duration = 101;

      this.all_discussion[0].class = "yellow-background";
      this.chosen_discussion = this.all_discussion[0];
      this.chosenCategory = this.chosen_discussion.alternatives[0];
      //  console.log(this.chosen_discussion);
      // console.log(this.chosenCategory);

      this.$root.$emit("bv::toggle::visible", "accordion-discussion-0");

      for (const [key, prop] of Object.entries({
        introduction: "introduction",
        overturn:"overturn",
        citations: "citation",
        conclusion: "conclusion",
        subject: "subject",
        question_presented: "question_presented",
        brief_answer: "brief_answer",
        recommendations: "recommendations",
      })) {
        this[prop] =
          data.values.find((element) => element.key === key) ?? this[prop];
      }

      this.quillIntroduction = `<p>${this.introduction.value}</p>`;
      this.quillConclusion = `<p>${this.conclusion.value}</p>`;

      this.quillDiscussionContent = this.all_discussion.map(
        (discussion) => `<p>${discussion.content.replaceAll("\n", "<br>")}</p>`
      );

      // if (this.citation == "") {
      //   this.citationString = JSON.parse(this.citation.value)
      //     .map((citation, index) => {
      //       if (typeof citation === "string") {
      //         return `<span>${
      //           index + 1
      //         }</span> <span>.</span> <span>${citation}</span>`;
      //       } else {
      //         return Object.entries(citation)
      //           .map(
      //             ([key, value]) =>
      //               `<span>${
      //                 index + 1
      //               }</span> <span>.</span> <span>${key}</span>`
      //           )
      //           .join("");
      //       }
      //     })
      //     .join("");
      // }
      // this.quillCitation = JSON.parse(this.citation.value)
      //   .map((citation, index) => {
      //     if (typeof citation === "string") {
      //       return `<span>${
      //         index + 1
      //       }</span> <span>.</span> <span>${citation}<br></span>`;
      //     } else {
      //       return Object.entries(citation)
      //         .map(
      //           ([key]) =>
      //             `<span>${
      //               index + 1
      //             }</span> <span>.</span> <span>${key}<br></span>`
      //         )
      //         .join("");
      //     }
      //   })
      //   .join("");

      if (this.$route.query.category == "Legal Argument") {
        this.quillStatement = `<p>${this.content.statement}</p>`;
      } else {
        this.quillSubject = `<p>${this.subject.value.replaceAll(
          "\n",
          "<br>"
        )}</p>`;
        this.quillQuestionsPresented = `<p>${this.question_presented.value}</p>`;
        this.quillBriefAnswer = `<p>${this.brief_answer.value}</p>`;

        // highlight bracketed text
        this.quillSubject = this.quillSubject.replace(
          /\[([^\]]+)\]/g,
          (match, text) => {
            const existingTags1 = this.quillSubject.match(
              new RegExp(
                `\\<span class="ql-bg-yellow"\\>(?!\\<span class="ql-bg-yellow"\\>\\[${text}\\]<\\/span>)`,
                "g"
              )
            );
            if (existingTags1 && existingTags1.length > 0) {
              return match; // If similar tag already exists, return the match as is
            } else {
              return `<span class="ql-bg-yellow">\[${text}\]<\/span>`; // Add the tag
            }
          }
        );
      }

      this.chosen_discussion.discussionID = 0;
      // console.log("Data ID", this.chosen_discussion.discussionID);
      // console.log("Chosen Discussion", this.chosen_discussion);
    },

    changeDiscussion(data) {
      clearTimeout(this.timeout_timer);
      this.chosen_discussion = this.all_discussion[data];
      this.chosen_discussion.discussionID = data;
      // this.chosen_discussion = this.chosen_discussion.push({ key: 'discussionID', value: data });
      // console.log("Data ID", data);
      // console.log("Chosen Discussion", this.chosen_discussion);

      this.chosen_discussion.alternatives.forEach((element, index) => {
        element.class = index === 0 ? "folderTab active" : "folderTab";
      });
      this.all_discussion.forEach((element, index) => {
        element.class = index == data ? "yellow-background" : "";
      });

      this.chosenCategory = this.chosen_discussion.alternatives[0];
    },

    timeoutSummary() {
      if (
        this.chosenCategory.type == "document" &&
        this.chosenCategory.elaborate_content.length == 0
      ) {
        this.timeout_timer = setTimeout(() => {
          if (!this.chosen_discussion.id || !this.chosenCategory.id) return;
          
          LegalWriter.openDocument(
            this.chosen_discussion.id,
            this.chosenCategory.id
          )
            .then((response) => {
              if (
                response.data.data.values.find((el) => el.key === "argument")
              ) {
                this.chosenCategory.elaborate_content = [
                  {
                    Discussion: response.data.data.values.find(
                      (el) => el.key === "argument"
                    ).value,
                  },
                ];
              } else {
                this.timeoutSummary();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }, 10000);
      } else {
        const DocType =
          this.chosenCategory.type == "document" ? "documents" : "alternatives";

        const me = this;

        this.timeout_timer = setTimeout(() => {
          if (!me.$route.params.id) return;

          LegalWriter.ViewLegalWriter(me.$route.params.id)
            .then((response) => {
              // console.log(
              //   response.data.data.discussions
              //     .find((x) => x.id === this.chosen_discussion.id)
              //     .documents.find((x) => x.id === this.chosenCategory.id)
              //     .completedPerCentage
              // );
              const completedPerCentage = response.data.data.discussions
                .find((x) => x.id === me.chosen_discussion.id)
                [DocType].find(
                  (x) => x.id === me.chosenCategory.id
                ).completedPerCentage;

              if (completedPerCentage == "Not enough data") {
                me.chosenCategory.completedPerCentage = "Not enough data";
              } else if (completedPerCentage < 100) {
                me.chosenCategory.completedPerCentage = completedPerCentage;
                me.timeoutSummary();
              } else {
                me.chosenCategory.completedPerCentage = 100;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }, 10000);
      }
    },

    changeTab(data) {
      this.chosenCategory = this.chosen_discussion.alternatives[data];
      this.chosen_discussion.alternatives.forEach((element, index) => {
        element.class = index == data ? "folderTab active" : "folderTab";
      });
      // console.log(this.chosenCategory);

      this.timeoutSummary();
      // Use the attribute selector to find elements with IDs containing "example"
      var elementsWithExample = document.querySelectorAll(
        '[id*="ori_content"]'
      );

      // Iterate through the found elements
      elementsWithExample.forEach((element) => {
        if (element.classList.contains("show")) {
          this.$root.$emit("bv::toggle::collapse", element.id);
        }
      });
    },

    viewSummary() {
      var routeName =
        this.chosenCategory.type == "case"
          ? "LegalResearchSummaryCase"
          : "LegalResearchSummaryDoc";

      this.$router.push({
        name: routeName,
        params: {
          id: this.chosen_discussion.id,
          case_id: this.chosenCategory.id,
        },
        query: {
          category: this.$route.query.category,
        },
      });
    },

    undoContent() {
      this.chosen_discussion.content = this.chosen_discussion.original_content;
    },

    replaceCase(selectedCase, id) {
      this.replace_case = [...new Set(this.replace_case)]; // Remove duplicates
      // console.log("replace case", this.replace_case);
      // this.chosen_discussion.content = this.replace_case;
      // console.log(this.replace_case)
      // console.log("selectedCase:", selectedCase)
      // console.log("Data ID", id);
      // console.log("Chosen Discussion", this.chosen_discussion)

      if (
        this.chosen_discussion.alternatives.findIndex(
          (alternative) => alternative.id === id
        ) !== -1
      ) {
        const x = this.chosen_discussion.discussionID;
        const discussion = selectedCase.Discussion.replace(/&/g, "&amp;");

        if (this.quillDiscussionContent[x].includes(discussion)) {
          this.quillDiscussionContent[x] = this.quillDiscussionContent[
            x
          ].replace(discussion, "");
        } else {
          this.quillDiscussionContent[x] += "<br>" + discussion;
        }
      }
    },

    saveContent() {
      const LArg = this.$route.query.category == "Legal Argument";

      var formattedDiscussion = this.all_discussion
        .map((element, index) => {
          // const formattedTheme = this.$options.filters.capitalize(
          //   element.theme
          // );
          return `${element.content}\n\n`;
        })
        .join("\n");
      var formattedCitation = JSON.parse(this.citation.value)
        .map((element, index) => {
          if (typeof element === "string") {
            return `${element}\n\n`;
          } else {
            const key = Object.keys(element)[0];
            return `${key}\n\n`;
          }
        })
        .join("");

      var brk = "\n\n\n";
      this.content_to_copy = LArg
        ? // Legal Argument
          "Statement\n\n" +
          this.content.statement +
          brk +
          "Introduction\n\n" +
          this.introduction.value +
          brk +
          "Discussion\n\n" +
          formattedDiscussion +
          brk +
          "\nConclusion\n" +
          this.conclusion.value +
          brk +
          "Citations\n\n" +
          formattedCitation
        : // Legal Memo ..
          "Subject\n\n" +
          this.subject.value +
          brk +
          "Question Presented\n" +
          this.question_presented.value +
          brk +
          "Brief Answer\n\n" +
          this.brief_answer.value +
          brk +
          "Discussion\n\n" +
          formattedDiscussion +
          "\nConclusion\n" +
          this.conclusion.value +
          brk +
          "Citations\n\n" +
          formattedCitation;

      formattedDiscussion = this.all_discussion
        .map((element, index) => {
          // const formattedTheme = this.$options.filters.capitalize(
          //   element.theme
          // );
          return `<p>${
            element.content
          }</p><br>`;
        })
        .join("");
      formattedCitation = JSON.parse(this.citation.value)
        .map((element, index) => {
          if (typeof element === "string") {
            return `${element}<br>`;
          } else {
            return `${Object.keys(element)[0]}<br>`;
          }
        })
        .join("");

      brk = "<br>";
      this.content_to_export = LArg
        ? // Lega Argument
          "<b>Statement</b><br>" +
          this.content.statement +
          brk +
          "<b>Introduction</b><br>" +
          this.introduction.value +
          brk +
          brk +
          "<b>Discussions</b><br>" +
          formattedDiscussion +
          "<b>Conclusion</b><br>" +
          this.conclusion.value +
          brk +
          brk +
          "<b>Citation</b><br>" +
          formattedCitation
        : // Legal Memo ..
          "<b>Subject</b><br>" +
          this.subject.value +
          brk +
          brk +
          "<b>Question Presented</b>" +
          this.question_presented.value +
          brk +
          brk +
          "<b>Brief Answer</b>" +
          this.brief_answer.value +
          brk +
          brk +
          "<b>Discussions</b><br>" +
          formattedDiscussion +
          "<b>Conclusion</b><br>" +
          this.conclusion.value +
          brk +
          brk +
          "<b>Citation</b><br>" +
          formattedCitation;
    },

    exportContent() {
      this.saveContent();

      if (this.pdf_status.length === 0) {
        this.$toast.error("Kindly select a format to export");
        return;
      }

      var status = ExportData(this.content_to_export.replaceAll("\n", "<br>"), [
        this.pdf_status.find((element) => element === "DOCUMENT"),
        this.pdf_status.find((element) => element === "PDF"),
      ]);

      if (status[0]) {
        this.$toast.success("Successfully downloaded Document");
      }
      if (status[1]) {
        this.$toast.success("Successfully downloaded PDF");
      }

      this.pdf_status = [];
      this.$bvModal.hide("export-edit-ai-suggestion");
    },

    authenticate() {
      Auth.getMe().then((res) => {
        localStorage.setItem("userInfo", JSON.stringify(res.data.data));
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));

        switch (userInfo?.nexlaw_credits) {
          case undefined:
          case "undefined":
          case null:
          case "null":
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userInfo");
            this.$router.push("/login");
            break;
        }

        const credits = res.data.data.nexlaw_credits;
        this.document_sum_credit_full =
          credits.full.legal_argument_summarize_document;
        this.document_sum_credit_remain =
          credits.full.legal_argument_summarize_document -
          credits.used.legal_argument_summarize_document;
      });
    },

    GenerateSummary(data) {
      this.file_in_summarize = true;

      const summarizeFunction =
        data.type === "document"
          ? LegalWriter.SummarizeDocument
          : LegalWriter.SummarizeAlternate;

      summarizeFunction(data.id)
        .then(() => {
          return LegalWriter.ViewLegalWriter(this.$route.params.id);
        })
        .then((response) => {
          const category =
            data.type === "document" ? "documents" : "alternatives";
          const chosenCategory = response.data.data.discussions
            .find((x) => x.id === this.chosen_discussion.id)
            [category].find((x) => x.id === this.chosenCategory.id);

          this.chosenCategory.completedPerCentage =
            chosenCategory.completedPerCentage;
          this.file_in_summarize = false;
          this.timeoutSummary();
          this.authenticate();
        })
        .catch((error) => {
          console.log(error);
          this.file_in_summarize = false;
        });
    },
  },

  // mounted() {
  //   setTimeout(() => {
  //     this.entered = true;
  //   }, 1000);
  // },

  created() {
    LegalWriter.ViewLegalWriter(this.$route.params.id)
      .then((response) => {
        this.pageLoading = false;

        var completedPerCentage = response.data.data.completedPerCentage;
        console.log("CompletePercentage",completedPerCentage);
         this.hasData = response.data.data && Object.keys(response.data.data).length > 0;

        if (completedPerCentage < 100) {
          // this.duration = completedPerCentage == 0 ? 20 : completedPerCentage;
           this.duration = completedPerCentage == 0 ? 1 : completedPerCentage;

          this.interval = setInterval(() => {
            if (!this.$route.params.id) return;

            LegalWriter.ViewLegalWriter(this.$route.params.id).then((res) => {
              completedPerCentage = res.data.data.completedPerCentage;
              if (completedPerCentage >= 100) {
                this.duration = completedPerCentage;
                clearInterval(this.interval);
                location.reload();
              } else {
                // this.duration =completedPerCentage == 0 ? 20 : completedPerCentage;
                  if (this.duration < completedPerCentage) {
                this.duration++;
              }
              }
            });
          }, 1000);
        } else {
          this.content = response.data.data;
          this.loadResult(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  destroyed() {
    clearInterval(this.interval);
    clearTimeout(this.timeout_timer);
  },

  beforeDestroy() {
    clearInterval(this.interval);
    clearTimeout(this.timeout_timer);
  },
};
</script>

<style scoped>
.btn-arrow-icon[aria-expanded="false"] .arrow-icon {
  transform: rotate(0deg);
  transition: 0.4s ease-out;
  color: #2c8de0;
}

.btn-arrow-icon[aria-expanded="true"] .arrow-icon {
  transform: rotate(180deg);
  transition: 0.2s ease-out;
  color: #2c8de0;
}

#file {
  display: none;
}

.h-60 {
  max-height: 760px;
  overflow-y: scroll;
}

.h-50 {
  max-height: 600px;
  overflow-y: scroll;
}

.blue-icon {
  color: #094890;
  font-size: 1.5rem;
  cursor: pointer;
}

.icon-blue {
  translate: -10px 5px;
}

.icon-export-blue {
  color: #094890;
  font-size: 2rem;
}

.row_details {
  margin: 0.5rem 0rem;
}

.content_discussion .active {
  background: rgba(250, 250, 134, 0.93);
}

.yellow-background {
  background: rgba(250, 250, 134, 0.93);
  margin-top: 0.5rem;
}

.warning-icon {
  display: inline;
  color: red;
  font-size: 1.2rem;
}

.upload-icon {
  color: #094890;
  font-size: 4rem;
}

.collasped .when-open {
  display: none;
}

.not-collapsed .when-closed {
  display: none;
}

.material-icons-outlined.when-closed {
  transform: rotate(0deg);
  transition: all 0.4s ease-out;
}

.material-icons-outlined.when-open {
  transform: rotate(180deg);
  transition: all 0.4s ease-out;
}

/* 
a:visited { ... }
a:focus { ... }
a:hover { ... }
a:active { ... } */

.text-primary {
  color: var(--color-primary) !important;
}

.bg-primary {
  background-color: var(--color-primary) !important;
}

@media only screen and (max-width: 768px) {
  .floating-menu {
    display: none !important;
  }
}

.floating-menu {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #094890;
  position: fixed;
  bottom: 20px;
  right: 80px;
  width: 50px;
  height: 50px;
  transform: translateY(70px);
  transition: all 250ms ease-out;
  border-radius: 50%;
  opacity: 0;

  border: 2px solid var(--color-primary);
}

.floating-menu.chat {
  bottom: 80px;
  right: 80px;
}

.floating-menu.enter:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  opacity: 1;
}

.floating-menu.enter {
  transform: translateY(0);

  opacity: 1;
  background: white;

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.14);
}

.floating-menu.expand {
  width: 210px;
  max-height: 350px;
  height: 350px;
  border-radius: 5px;
  cursor: auto;
  opacity: 1;
  background: white;
}

.floating-menu.expand .icon-robot {
  display: none;
}

.floating-menu :focus {
  outline: 0;
  /* box-shadow: 0 0 3pt 2pt rgba(14, 200, 121, 0.3); */
}

.floating-menu button {
  background: #2c8ee1;
  border-radius: 20px;
  font-size: 10px;
  /* padding: 0 1rem; */
  border: 0;
  color: grey;
  text-transform: uppercase;
  cursor: pointer;
}

.floating-menu .chat {
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 50%;
  transition: all 350ms ease-out;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.floating-menu .chat.enter {
  opacity: 1;
  border-radius: 0;
  margin: 10px;
  width: auto;
  height: auto;
}

/* .floating-menu .chat .header {
  flex-shrink: 0;
  padding-bottom: 10px;
  display: flex;
  background: transparent;
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
  padding-bottom: 2rem;
} */
/* .floating-menu .chat .header .title {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 5px;
}
.floating-menu .chat .header button {
  flex-shrink: 0;
} */

.floating-menu .chat .messages {
  padding: 3rem 10px 0px;
  margin: 0;
  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
  border-radius: 4px;
  background: transparent;
}

.floating-menu .chat .messages::-webkit-scrollbar {
  width: 5px;
}

.floating-menu .chat .messages::-webkit-scrollbar-track {
  border-radius: 5px;
  /* background-color: rgba(25, 147, 147, 0.1); */
  background-color: transparent;
}

.floating-menu .chat .messages::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #2c8ee1 !important;
  border: none;
}

.floating-menu .chat .messages li {
  position: relative;
  clear: both;
  display: inline-block;
  padding: 14px;
  margin: 0 0 20px 0;
  font: 12px/16px "Noto Sans", sans-serif;
  border-radius: 10px;
  background-color: rgba(246, 248, 250, 0.836);
  word-wrap: break-word;
  max-width: 81%;
  border: none;
}

.floating-menu .chat .messages li:before {
  position: absolute;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  content: "";
  background-size: cover;
}

.floating-menu .chat .messages li:after {
  position: absolute;
  top: 10px;
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid rgba(246, 248, 250, 0.836);
  border-right: 1px solid rgba(246, 248, 250, 0.836);
}

.floating-menu .chat .messages li.other {
  animation: show-chat-odd 0.15s 1 ease-in;
  -moz-animation: show-chat-odd 0.15s 1 ease-in;
  -webkit-animation: show-chat-odd 0.15s 1 ease-in;
  float: right;
  margin-right: 45px;
  color: #2c8ee1;
}

.floating-menu .chat .messages li.other:before {
  right: -45px;
  background-image: url(https://github.com/Thatkookooguy.png);
}

.floating-menu .chat .messages li.other:after {
  border-right: 10px solid transparent;
  right: -10px;
}

.floating-menu .chat .messages li.self {
  animation: show-chat-even 0.15s 1 ease-in;
  -moz-animation: show-chat-even 0.15s 1 ease-in;
  -webkit-animation: show-chat-even 0.15s 1 ease-in;
  float: left;
  margin-left: 45px;
  color: #2c8ee1;
}

.floating-menu .chat .messages li.self:before {
  left: -45px;
  background-image: url(https://github.com/ortichon.png);
}

.floating-menu .chat .messages li.self:after {
  border-left: 10px solid transparent;
  left: -10px;
}

.floating-menu .chat .footer {
  flex-shrink: 0;
  display: flex;
  padding-top: 10px;
  max-height: 90px;
  background: transparent;
}

.floating-menu .chat .footer .text-box {
  border-radius: 3px;
  background: rgba(246, 248, 250, 0.993);
  /* border: 2px solid #2c8ee1; */
  min-height: 100%;
  width: 100%;
  margin-right: 5px;
  color: #0ec879;
  overflow-y: auto;
  padding: 2px 5px;
}

.floating-menu .chat .footer .text-box::-webkit-scrollbar {
  width: 5px;
}

.floating-menu .chat .footer .text-box::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgba(25, 147, 147, 0.1);
}

.floating-menu .chat .footer .text-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(25, 147, 147, 0.2);
}

#aiSuggButton {
  transform: translate(-0.1rem, 0.5ex);
  height: 30px;
  width: 110px;
}

#saveChangesButton {
  transform: translate(-0.1rem, 0.5ex);
  height: 30px;
  width: 110px;
}

.form-control::file-selector-button {
  background-color: #9f9fa09a;
  border: 1px solid #9f9fa09a;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}
</style>

<style>
.LegalAISug-discussion-editor .ql-editor {
  max-height: 500px !important;
}
</style>